.navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgb(238, 233, 229);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar a {
    display: flex;
    align-items: center;
}

[data-theme="dark"] .navbar {
    background-color: #00011d;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
} 

.theme {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar img {
    display: flex;
    align-items: center;
    padding-top: 0.55rem;
}

.icon {
    padding-top: 1rem;
}

.navbar button {
    font-family: 'PTSerif', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    color: #A8815B;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
    padding: 5px 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.navbar button:hover {
    color: pink;
}
