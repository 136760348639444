.title {
    font-size: 2.5rem;
    text-align: center;
    color: #a4875a;
    margin-bottom: 2rem;
  }
  
  .priceList {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .section {
    margin-bottom: 2rem;
  }
  
  .sectionTitle {
    font-size: 1.5rem;
    color: #333;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }
  
  .separator {
    border: none;
    border-top: 1px solid #d0c7a1;
    margin: 0.5rem 0 1rem;
  }
  
  .item {
    display: flex;
    justify-content: space-between;
    font-family: NotoSerifBold, sans-serif;
    font-size: 0.75rem;
    color: #555;
    padding: 0.5rem 0;
  }
  
  .footer {
    text-align: center;
    font-size: 1.25rem;
    color: #d0c7a1;
    margin-top: 2rem;
  }
  