@font-face {
  font-family: 'NotoSerif';
  src: url('./assets/fonts/NotoSerifDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerifBold';
  src: url('./assets/fonts/NotoSerifDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PTSerif';
  src: url('./assets/fonts/PTSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.desktop-nav {
  display: none;
}

.mobile-nav {
  display: block;
}

@media (min-width: 768px) {
  .desktop-nav {
    display: block;
  }

  .mobile-nav {
    display: none;
  }
}

body {
  background-color: rgb(255, 255, 255);
  font-family: 'NotoSerif', serif;
  color: #A8815B;
}

strong, .bold-text {
  font-family: 'NotoSerifBold', serif;
}
